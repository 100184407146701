import React from "react";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete'; //https://material-ui.com/components/autocomplete/
import TEAMS from "../../data/teams.json";

export default class ConferenceDropdown extends React.Component 
  {
    state = {
      selectedConference: this.getDefault()
    }
    selectionChanged = (event,newValue) =>  {
        this.setState({selectedConference:newValue})
        const newConference= (newValue == null) ? "All Conferences" : newValue
        this.props.onSelectedConferenceChanged(newConference) //pass it up to container
    }
    getDefault() {
      return this.ConferenceList().find((conference)=>conference===this.props.defaultConference)
    }

    ConferenceList() {
      let conferences = [...new Set(TEAMS.map(t=>t["conference"]))];
      conferences.push("All Conferences")
      return conferences.sort();
    }
   
    render() {
        return (
            <Autocomplete
              id="conferenceSelect"
              value={this.state.selectedConference}
              options={this.ConferenceList()}
              getOptionLabel={(conference) => `${conference}`}
              style={{ marginLeft:"auto",marginRight:"0"}}
              onChange={this.selectionChanged}
              renderInput={(params) => <TextField {...params} label="Conference" variant="outlined" />}
            />
        );
    }
  

  }