import React, { useState } from "react"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { DataGrid } from '@mui/x-data-grid';
import { formatRating } from "../utils";
import ConferenceDropdown from "./conferencedropdown";
import { SeasonRatings, Team, TeamRecord } from "../dataaccess";

/*
  a RatingsGrid displays the ratings and records for all of the teams in a season
    props:
      - season : a season_code
    
*/
const RatingsGrid = (props) => {
  const [selectedConference, selectConference] = useState("All Conferences");

  // load all the ratings from the specified season
  // the RatingsGrid will show one row per element in this list
  let RATINGS = SeasonRatings(props.season);

  // define the columns of the grid and their display properties
  const columns = [
    { field: 'ordinal', headerName: '', width: 36 },
    { field: 'team', headerName: 'Team', width: 140, },
    { field: 'rating', headerName: 'Rating', width: 150 },
    { field: 'conference', headerName: 'Conference', width: 160 },
    { field: 'overall_wins', headerName: 'W', width: 36 },
    { field: 'overall_losses', headerName: 'L', width: 36 },
    { field: 'conference_wins', headerName: 'W', width: 36 },
    { field: 'conference_losses', headerName: 'L', width: 36 }
  ];
  const columnGroupingModel=[
    {
      groupId: 'team',headerName:'',
      children: [{field: 'ordinal'},{field: 'team'},{field: 'rating'},{field: 'conference'}],
    },
    {
      groupId: 'overall record',headerName:'Overall',
      children: [{field: 'overall_wins'},{field: 'overall_losses'}],
    },{
      groupId: 'conference record',headerName:'Conference',
      children: [{field: 'conference_wins'},{field: 'conference_losses'}],
    }
  ]
  //extract some of the metadata from the ratings
  // to display at the top of the grid
  const home_edge = RATINGS['home_edge'];
  const as_of_date = RATINGS['as_of_date'];

  // for each team in the ratings, assemble the data to display
  //  only include rows for the selected conference, if one has been selected
  const rows = RATINGS['teams'].map(t => {
    const team_data = Team(t['team_code']);
    const team_record = TeamRecord(t['team_code'], props.season)
    const row = {
      "id"               : t['team_code'],
      "ordinal"          : t['ordinal'],
      "team"             : (team_data !== null) ? team_data['school'] : t['team_code'],
      "conference"       : (team_data !== null) ? team_data['conference'] : "",
      "rating"           : formatRating(t['rating']),
      "overall_wins"     : team_record['overall']['W'],
      "overall_losses"   : team_record['overall']['L'],
      "conference_wins"  : team_record['conference']['W'],
      "conference_losses": team_record['conference']['L'],
    };
    return row;
  }).filter(t => (selectedConference === "" || selectedConference === "All Conferences" || t['conference'] === selectedConference));



  //https://mui.com/x/api/data-grid/data-grid/
  return (
    <Paper elevation={3}>
      <Box sx={{ padding: 2 }}>
        <Table width="100%" className="ratingMetadata">
          <TableBody>
            <TableRow>
              <TableCell sx={{ padding: 0, verticalAlign: 'top' }}>
                <div className="ratingMetadata">
                  Ratings from games played through {as_of_date}<br />
                  Home Edge: {formatRating(home_edge)}
                </div>
              </TableCell>
              <TableCell sx={{ paddingTop: 0 }}>
                <ConferenceDropdown defaultConference="All Conferences" onSelectedConferenceChanged={(c) => selectConference(c)} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <DataGrid experimentalFeatures={{ columnGrouping: true }}
          rows={rows}
          columns={columns}
          columnGroupingModel={columnGroupingModel}
          density="compact"
          getCellClassName={() => "rating"}
          initialState={{
            paginataion: {
              paginationModel: {
                pageSize: 100,
              },
            }, sortModel: [{ field: 'rating', sort: 'desc' }]
          }}
          disableRowSelectionOnClick
          disableColumnMenu={true}
        />
      </Box>
    </Paper>
  );
};
export default RatingsGrid;